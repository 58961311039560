const VUE_APP_PLATFORM_URL = process.env.VUE_APP_PLATFORM_URL;
const VUE_APP_MARKET_URL = process.env.VUE_APP_MARKET_URL;
// const VUE_APP_INTERNATIONAL_URL = process.env.VUE_APP_INTERNATIONAL_URL;
// const VUE_APP_PAY_URL = process.env.VUE_APP_PAY_URL;
// const VUE_APP_EXCHANGE_URL = process.env.VUE_APP_EXCHANGE_URL;

const newtk = localStorage.getItem('accessToken')
const companyReference = localStorage.getItem("companyReference")
const companyAccountId = localStorage.getItem("companyAccountId")
const companyId = localStorage.getItem("companyId")

export function handleError(error, language) {
    switch (error.response.status) {
        case 401:
            localStorage.removeItem('accessToken');
            window.location.href = `/${language}/account/login`
            break;

        case 403:
            window.location.href = `${VUE_APP_PLATFORM_URL}${language}/account/consent/ask?scope=3fa85f64-5717-4562-b3fc-2c963f66afa6&tk=${this.newtk}&destiny=client-space`
            break;

        case 409:
            if (error.response.data === "merchant_must_have_compliance") {
                window.location.href = `${VUE_APP_MARKET_URL}${language}/account/token-auth?tk=${newtk}&destiny=merchant/company/${companyReference}`
            }
            
            if (error.response.data === "account_must_have_kyc") {
                window.location.href = `/${language}/account/kyc`
            }

            if (error.response.data === "account_must_have_compliance") {
                window.location.href = `/${language}/account/compliance`
            }

            if (error.response.data === "account_must_have_company_compliance") {
                window.location.href = `/${language}/account/compliance-company?id=${companyId}`
            }

            if (error.response.data === "account_already_have_wallet_stake") {
                window.location.href = `/${language}/client-space/purchase`
                // this.$router.push({ name: 'ClientSpacePurchase', params: { lang: this.$route.params.lang } })
            }

            if (error.response.data === "account_must_be_manualy_checked") {
                window.location.href = `/${language}/account/pending`
            }

            if (error.response.data === "account_must_have_beneficial_owner") {
                window.location.href = `/${language}/account/ubo/person`
                // this.$router.push({ name: 'UboPerson', params: { lang: this.$route.params.lang } })
            }

            if (error.response.data === "account_must_have_company_beneficial_owner") {
                window.location.href = `/${language}/account/ubo/company?id=${companyAccountId}`
                // this.$router.push({ name: 'UboCompany', params: { lang: this.$route.params.lang } })
            }

            if (error.response.data === "wrong_account_type") {
                this.blockPurchase = true
            }

            if (error.response.data === "account_must_have_wallet_stake") {
                window.location.href = `/${language}/client-space/wallet-stake`
            }

            if (error.response.data === "international_company_member_not_found") {
                console.log("403")
            }
            break;

        default:
            console.log(`Sorry, we are out of ${error.response.status}.`);
    }
}