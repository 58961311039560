<template>
    <!-- <div class="register-phase" :class="{center: dataSource != 'Private' && dataSource != 'Public'}"> -->
    <div class="register-phase" :class="{center: dataSource == 'Private' || dataSource == 'Public'}">
        <h2 class="title-primary">DNE <span>T</span>oken</h2>
        <p class="emphasis">{{ getMyLanguage("purchase-token", "purchase-token.content.dne-token.emphasis") }}</p>

        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="btn-primary btn-register"
                    v-if="!$store.state.statusLogin"
                    @click="lnkRegister()">

                    {{ getMyLanguage("dne-platform","home.banner.register") }}
                </div>
                
                <div class="btn-primary btn-register"
                    v-else
                    @click="lnkPurchase()">

                    {{ getMyLanguage("dne-platform","home.banner.cta") }}
                </div>


                <!-- <div v-if="$store.state.phaseCurrent.phase_type == 'Private'" @click="lnkRegister()" class="btn-primary btn-register">{{ getMyLanguage("purchase-token","purchase-token.content.phase.interested") }}</div> -->
            
                <!-- <a v-if="$store.state.phaseCurrent.phase_type == 'Private'" :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/private-sale`" class="btn-primary btn-register" target="_blank">
                    {{ getMyLanguage("purchase-token","purchase-token.content.phase.interested") }}
                </a> -->

                <!-- <a v-if="$store.state.phaseCurrent.phase_type != 'Private'" :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/account/register`" class="btn-primary btn-register" target="_blank">
                    {{ getMyLanguage("login","login.register-now") }}
                </a> -->
            </div>
            

            <div class="col-12 col-sm-6">
                <a :href='"https://cdn.dneecosystem.com/docs/WhitePaper-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="download-whitepaper">
                    <span>
                        Whitepaper
                        <small>PDF Download</small>
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>


<script>
    const $ = require('jquery')
    window.$ = $

    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        props: {
            dataSource: String
        },

        data(){
            return{
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                flagSelected: "en"
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },
            
            lnkRegister(){
                window.location.href = `${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/register`                
            },

            lnkPurchase(){
                window.location.href = `/${this.flagSelected}/client-space/purchase`
            }

            // lnkRegister(){
            //     let urlRegister
                
            //     if ( this.$store.state.phaseCurrent.phase_type == "Private" ){
            //         urlRegister = `${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/lead/`
            //     } else {
            //         urlRegister = `${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/register/`
            //     }

            //     window.open(urlRegister, "_blank")
            // }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="@/assets/scss/_register-phase.scss" lang="scss" scoped />
