<template>
    <div class="home">
        <div id="dne"></div>
        <Header />

        <div id="clientSpace">
            <section class="client-space">
                <div class="anc" id="client-space"></div>
                <div class="container">
                    <ClientSpaceResume :hidePurchase="purcaseHide" />
                </div>
            </section>

            <a id="anchor-content"></a>

            <div class="load load-200" v-if="!showContent"></div>
            
            <section class="purchase-pay" v-if="showContent">
                
                <div class="container" v-if="blockPurchase">
                    <div class="box-error" v-if="blockPurchase">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error","block-purchase") }}</p>
                    </div>
                </div>
                
                <div class="container" v-if="!blockPurchase">

                    <div class="row">
                        <ul class="breadcrumb">
                            <li class="active"><a :href="`/${flagSelected}/client-space/`"><span>{{ getMyLanguage("client-space", "client-space.resume.client-space") }}</span></a></li>
                            <li><a :href="`/${flagSelected}/client-space/purchase`"><span>{{ getMyLanguage("client-space", "client-space.increase-cta") }}</span></a></li>
                        </ul>
                    </div>


                    <!-- NO HASQUOTA -->
                    <div class="row no-has-quota phases" v-if="showContent && (dataTokenSale.quota.available < dataTokenSale.quota.min_amount)">
                        <div class="col-12">
                            <h2 class="title-primary">DNE <span>T</span>oken</h2>
                            
                            <div class="box-success">
                                <div class="quote-limit">
                                    <div>
                                        {{dataTokenSale.quota.used}}/{{dataTokenSale.quota.max_amount}}
                                        DNE BOX
                                    </div>
                                </div>

                                <h3 class="title-secondary">{{ getMyLanguage("box-success", "title-purchase-limit") }}</h3>

                                <div v-if="dataTokenSale.phase.phase_name != 'Default Campaign'" v-html='getMyLanguage("box-success", "purchase-limit")'></div>
                                
                                <div v-else v-html='getMyLanguage("box-success", "purchase-limit-mouth")'></div>
                            </div>                        
                        </div>

                        <Phases v-if="dataTokenSale.phase.phase_name != 'Default Campaign'" />
                    </div>
                    
                    
                    <!-- PHASES -->
                    <div class="row phases" v-if="showContent && $store.state.profile.account_status != 'Verified'">
                        <div class="col-12">
                            <h2 class="title-primary">DNE <span>T</span>oken</h2>

                            <h3 class="alert-phase">{{ getMyLanguage("client-space", "client-space.purchase.msg-phases") }}</h3>
                        </div>

                        <Phases />
                    </div>

                    <!-- PAY -->
                    <div class="row pay" v-if="showContent && (dataTokenSale.quota.available >= dataTokenSale.quota.min_amount) && $store.state.profile.account_status === 'Verified'">
                        <div class="col-12">
                            <h2 class="title-primary">DNE <span>T</span>oken</h2>
                        </div>

                        <!-- ORDER SUMMARY -->
                        <div class="col-12 col-lg-5 resume-purchase">
                            <h6>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.title") }}</h6>

                            <!-- <div class="promotion-price">
                                <h3>{{dataTokenSale.phase.phase_name}}</h3>
                                <p>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.quantity-tokens") }}: <strong>{{summary.quantityTokenString}} Tokens</strong></p>
                                <p>
                                    {{ getMyLanguage("client-space", "client-space.purchase.order-summary.promotion-price") }}: 
                                    <strong>{{summary.priceFinalString}} ({{ getMyLanguage("client-space", "client-space.purchase.order-summary.with-discount") }}{{dataTokenSale.phase.phase_discount}}{{ getMyLanguage("client-space", "client-space.purchase.order-summary.percent-off") }})
                                        <span v-if="dataTokenSale.phase.phase_bonus > 0"> + {{dataTokenSale.phase.phase_bonus}}% {{getMyLanguage("purchase-token","purchase-token.content.phase.bonus")}}</span>
                                    </strong>
                                </p>
                            </div> -->
                            
                            <p>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.original-price") }}: <strong>{{summary.originalPriceString}} / {{summary.quantityTokenString}} Tokens</strong></p>
                            <p v-if="dataTokenSale.phase.phase_bonus > 0">{{ getMyLanguage("client-space", "client-space.purchase.order-summary.total") }}: <strong>{{summary.totalTokenString}} DNE Tokens</strong></p>
                            <p v-if="dataTokenSale.phase.phase_stake_months > 0">{{ getMyLanguage("client-space", "client-space.purchase.order-summary.stake") }}: <strong>{{dataTokenSale.phase.phase_stake_months}} {{getMyLanguage("purchase-token","purchase-token.content.phase.months")}}</strong></p>


                            <!-- PAYMENT CONDITIONS -->
                            <div class="msg-payment-condition">
                                <div v-for="(listPaymentConditions,index) in dataTokenSale.payment_types" :key="index">
                                    <div v-if='methodPay === listPaymentConditions.paymentTypeCode' v-html='getMyLanguage("client-space",listPaymentConditions.paymentTypeCode + ".text")'></div>
                                </div>
                            </div>

                            <div class="alert-reserve" v-html='getMyLanguage("client-space", "client-space.alert-reserve")'></div>
                        </div>


                        <!-- FORM PAY -->
                        <div class="col-12 col-lg-5 pay-frame">
                            <div class="load" v-if="dataPurchase.account_id == null"></div>
                            
                            <div v-if="dataPurchase.account_id != null">
                                <div class="row">
                                    <div class="col-12 qtd-package">
                                        <h4>{{ getMyLanguage("client-space", "client-space.purchase.form.select-package") }}</h4>

                                        <div class="quantityBuy">
                                            <img src="@/assets/images/client-space/dne-coin.png" alt="DNE Token">
                                            <div class="quantity">
                                                <div class="tooltip tooltip-less">{{ getMyLanguage("errors", "errors.min") }} {{dataTokenSale.quota.min_amount}} {{ getMyLanguage("errors", "errors.packages") }}</div>
                                                <div class="tooltip tooltip-more">{{ getMyLanguage("errors", "errors.max") }} {{dataTokenSale.quota.available}} {{ getMyLanguage("errors", "errors.packages") }}</div>

                                                <!-- <div class="btnQtd btn-less disable" v-if="disableQuantity === false" @click="upSummary(dataPurchase.quantity --)" @touchstart="quantityMore()" @touchend="quantityStop()" @mousedown="quantityLess()" @mouseup="quantityStop()" @mouseout="quantityStop()">-</div>
                                                <div class="form" v-text="dataPurchase.quantity"></div>
                                                <div class="btnQtd btn-more" v-if="disableQuantity === false" @click="upSummary(dataPurchase.quantity ++)" @touchstart="quantityMore()" @touchend="quantityStop()" @mousedown="quantityMore()" @mouseup="quantityStop()" @mouseout="quantityStop()">+</div> -->

                                                <div class="btnQtd btn-less disable" @click="upSummary(dataPurchase.quantity --)" @touchstart="quantityMore()" @touchend="quantityStop()" @mousedown="quantityLess()" @mouseup="quantityStop()" @mouseout="quantityStop()">-</div>
                                                <div class="form" v-text="dataPurchase.quantity"></div>
                                                <div class="btnQtd btn-more" @click="upSummary(dataPurchase.quantity ++)" @touchstart="quantityMore()" @touchend="quantityStop()" @mousedown="quantityMore()" @mouseup="quantityStop()" @mouseout="quantityStop()">+</div>
                                            </div>
                                        </div>

                                        <div class="quantityBuyRange">
                                            <div class="purchased-packages">
                                                {{getMyLanguage("client-space","client-space.purchase.order-summary.purchased-packages")}}
                                                {{dataTokenSale.quota.used}}
                                            </div>
                                            <input type="range" :min="dataTokenSale.quota.min_amount" :max="dataTokenSale.quota.available" step="1" v-model="dataPurchase.quantity" @touchend="upSummary(dataPurchase.quantity)"  @mouseup="upSummary(dataPurchase.quantity)">
                                            <div class="quantity-min-max">
                                                <span class="minRange">{{dataTokenSale.quota.min_amount}}</span>
                                                <span class="maxRange">{{dataTokenSale.quota.available}}</span>
                                            </div>
                                        </div>

                                        <div class="alert-lost-token anime" v-if="(dataPurchase.quantity > (dataTokenSale.quota.available - dataTokenSale.quota.min_amount)) && dataPurchase.quantity != dataTokenSale.quota.available">
                                            <p>
                                                <small>
                                                    <strong>{{dataTokenSale.quota.available - dataPurchase.quantity}} </strong>
                                                    {{getMyLanguage("client-space","client-space.purchase.order-summary.lost-token")}}
                                                </small>
                                            </p>
                                        </div>

                                        <div class="summary-mobile">
                                            <table class="summary-mobile-cont">
                                                <tr>
                                                    <td><p><small>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.quantity-tokens") }}:</small></p></td>
                                                    <td class="text-right"><strong class="asset-dne">{{summary.quantityTokenString}}</strong></td>
                                                </tr>
                                                
                                                <!-- <tr>
                                                    <td><p><small>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.token-bonus-stake") }}:</small></p></td>
                                                    <td class="text-right"><strong class="asset-dne">{{dataPurchase.quantity * dataTokenSale.phase.purchase_bonus}}</strong></td>
                                                </tr> -->
                                                
                                                <tr class="calculated-value promotion-price" v-if="dataTokenSale.phase.phase_bonus > 0">
                                                    <td><p><small>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.token-bonus-stake") }}:</small></p></td>
                                                    <td class="text-right">
                                                        <strong>
                                                            <span class="asset-dne">+ {{dataPurchase.quantity * dataTokenSale.phase.purchase_bonus}}</span>
                                                            <small>({{ getMyLanguage("client-space","client-space.purchase.order-summary.total") }}:&nbsp;{{((dataPurchase.quantity * dataTokenSale.phase.purchase_bonus) + summary.quantityToken)}}&nbsp;{{ getMyLanguage("client-space","client-space.purchase.order-summary.tokens") }})</small>
                                                        </strong>
                                                    </td>
                                                </tr>
                                                
                                                <tr>
                                                    <td><p><small>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.original-price") }}:</small></p></td>
                                                    <td class="text-right"><strong>{{summary.originalPriceString}}</strong></td>
                                                </tr>
                                                
                                                <!-- <tr class="promotion-price">
                                                    <td><p><small>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.promotion-price") }}:</small></p></td>
                                                    <td class="text-right">
                                                        <strong>
                                                            <span>{{summary.priceFinalString}}</span>
                                                            <small>({{ getMyLanguage("client-space", "client-space.purchase.order-summary.with-discount") }}{{dataTokenSale.phase.phase_discount}}{{ getMyLanguage("client-space", "client-space.purchase.order-summary.percent-off") }})</small>
                                                        </strong>
                                                    </td>
                                                </tr> -->
                                                
                                                <tr v-if='methodPay === "crypto"' class="calculated-value">
                                                    <td class="no-border"><p class="value-ticker"><small>TETHER USDT:</small></p></td>
                                                    <td class="no-border text-right">
                                                        <strong>
                                                            <span class="tether">{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}}</span>
                                                            <small>({{summary.priceFinalString}} x {{ticker.toLocaleString("pt-PT", {style:"currency", currency:"USD"})}})</small>
                                                        </strong>
                                                    </td>
                                                </tr>

                                                <tr v-if='methodPay === "swapix"' class="calculated-value">
                                                    <td class="no-border"><p class="value-ticker"><small>SWAPIX USDT:</small></p></td>
                                                    <td class="no-border text-right">
                                                        <strong>
                                                            <span>{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}}</span>
                                                            <small>({{summary.priceFinalString}} x {{tickerPix.toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}})</small>
                                                        </strong>
                                                    </td>
                                                </tr>

                                                <tr v-if='methodPay === "crypto" || methodPay === "swapix"' class="quote-updates">
                                                    <td colspan="2">
                                                        <em>{{getMyLanguage("client-space","quote-updates")}}</em>
                                                        <strong v-if="quoteTimer >= 60"> {{quoteTimerMinutes}} <span v-if="quoteTimerMinutes > 1">{{getMyLanguage("client-space","quote-updates.minutes")}}</span><span v-if="quoteTimerMinutes == 1">{{getMyLanguage("client-space","quote-updates.minute")}}</span></strong>
                                                        <strong v-if="quoteTimer < 60"> {{quoteTimer}} {{getMyLanguage("client-space","quote-updates.seconds")}}</strong>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>


                                    <!-- SELECT PAYMENT TYPE -->
                                    <div class="col-12 select-payment-method" v-if="dataTokenSale.payment_types.length > 1">
                                        <h4>{{ getMyLanguage("client-space", "client-space.purchase.form.select-payment-method") }}</h4>
                                        <ul>
                                            <li v-for="(listPaymentType,index) in dataTokenSale.payment_types" :key="index" :class="[{hide: !listPaymentType.enabled},listPaymentType.paymentTypeCode]">
                                                <div class="select-payment-type" v-if="listPaymentType.enabled" :class='{active: methodPay === listPaymentType.paymentTypeCode}' @click='methodPay = listPaymentType.paymentTypeCode, ticker = listPaymentType.ticker, dataPurchase.paymentTypeId = listPaymentType.paymentTypeId'>
                                                    <span v-if='listPaymentType.paymentTypeCode != "credit_card"'>{{listPaymentType.name}}</span>
                                                    <span v-if='listPaymentType.paymentTypeCode === "credit_card"'>{{ getMyLanguage("client-space","Credit Card") }}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>



                                <!-- CREDIT CARD -->
                                <div class="row method-pay-animation" v-if='methodPay === "credit_card"'>
                                    <div class="col-12">
                                        <div class="alert-card" v-html='getMyLanguage("client-space","client-space.purchase.form.credit-card.warning")'></div>
                                    </div>
                                    <div class="col-12">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.purchase.form.lbl-cardHolder") }}
                                            <input type="text" class="form" :class="{error: validateCard.cardHolder == false}" id="name-card" v-model="dataPurchase.cardHolder" required>
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.purchase.form.lbl-cardNumber") }}
                                            <input type="text" class="form" :class="{error: validateCard.cardNumber == false}" id="card-number" v-mask="'0000 0000 0000 0000'" placeholder="____ ____ ____ ____" v-model="dataPurchase.cardNumber" required>
                                        </label>
                                    </div>
                                    <div class="col-7">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.purchase.form.lbl-expiry") }}
                                            <input type="text" class="form" :class="{error: validateCard.expiry == false}" id="card-expiry" v-mask="'00/00'" placeholder="__/__" v-model="dataPurchase.expiry" required>
                                        </label>
                                    </div>
                                    <div class="col-5">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.purchase.form.lbl-cvv") }}
                                            <input type="text" class="form" :class="{error: validateCard.cvv == false}" id="card-cvv" v-mask="'000'" placeholder="___" v-model="dataPurchase.cvv" required>
                                        </label>
                                    </div>
                                </div>


                                <!-- CHECKS -->
                                <div class="checks-lnkTerms">
                                    <div class="checks-terms">

                                        <!-- CHECKS SWAPIX -->
                                        <div class="checks-suapix" v-if='methodPay === "swapix"'>
                                            <div class="form-group-check">
                                                <input type="checkbox" id="swapixCheck01" class="form-check" v-model="accepts.swapix01">
                                                <label for="swapixCheck01" class="form-check-label"><small>{{ getMyLanguage("client-space", "client-space.alert-swapix01") }}</small></label>
                                            </div>

                                            <div class="form-group-check">
                                                <input type="checkbox" id="swapixCheck02" class="form-check" v-model="accepts.swapix02">
                                                <label for="swapixCheck02" class="form-check-label"><small>{{ getMyLanguage("client-space", "client-space.alert-swapix02") }}</small></label>
                                            </div>
                                            
                                            <div class="form-group-check">
                                                <input type="checkbox" id="swapixCheck03" class="form-check" v-model="accepts.swapix03">
                                                <label for="swapixCheck03" class="form-check-label"><small>{{ getMyLanguage("client-space", "client-space.alert-swapix03") }}</small></label>
                                            </div>
                                        </div>
                                        
                                        <div class="form-group-check">
                                            <input type="checkbox" id="acceptConditions" class="form-check" v-model="accepts.Conditions">
                                            <label for="acceptConditions" class="form-check-label"><small>{{ getMyLanguage("client-space", "client-space.alert-reserve.accept") }}</small></label>
                                        </div>
                                        
                                        <div class="form-group-check">
                                            <input type="checkbox" id="acceptTerms" class="form-check" v-model="accepts.Terms">
                                            <label for="acceptTerms" class="form-check-label">
                                                <small>
                                                    {{ getMyLanguage("client-space", "client-space.alert-reserve.accept-terms") }}
                                                    <a :href='"/" + flagSelected + "/terms-and-conditions"' target="_blank" v-html='getMyLanguage("client-space", "client-space.alert-reserve.accept-terms.lnk")'></a>
                                                </small>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- BOX ERROR -->
                            <!-- <div class="col-12">
                                <div class="box-error" v-if="showContent && errorsPost.dataPurchase != false">
                                    <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                    <p v-if='errorsPost.dataPurchase != ("invalid_request_error" && "third_party_stripe_create_payment_intent_failure")'>{{ getMyLanguage("box-error","msg-error") }}</p>
                                    <p v-if='errorsPost.dataPurchase === "third_party_stripe_create_payment_intent_failure"'>{{ getMyLanguage("errors","errors.third_party_stripe_create_payment_intent_failure") }}</p>
                                    <p v-if='errorsPost.dataPurchase === "invalid_request_error"'>{{ getMyLanguage("errors","errors.third_party_stripe_create_account_failure") }}</p>
                                    <div class="cod-error">
                                        Cód.: {{errorsPost.dataPurchase}}
                                    </div>
                                </div>
                            </div> -->

                            <!-- OPEN MODAL CONFIRM -->
                            <div v-if='methodPay != "swapix"'>
                                <input type="hidden" v-model="dataPurchase.phaseProductId">

                                <div class="btn-primary disabled" v-if='(methodPay != "crypto" && methodPay != "swapix") && (accepts.Conditions != true || accepts.Terms != true)'>{{ getMyLanguage("client-space", "client-space.purchase.form.lbl-pay") }} <small>{{summary.priceFinalString}}</small></div>
                                <div @click="openModalConfirm()" expand="block" class="btn-primary" v-if='(methodPay != "crypto" && methodPay != "swapix") && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("client-space", "client-space.purchase.form.lbl-pay") }} <small>{{summary.priceFinalString}}</small></div>

                                <div class="btn-primary btn-pay-crypto disabled" v-if='(methodPay === "crypto" || methodPay === "swapix") && (accepts.Conditions != true || accepts.Terms != true)'>{{ getMyLanguage("client-space", "client-space.purchase.form.lbl-pay") }} <small>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT <small>({{summary.priceFinalString}})</small></small></div>
                                <div @click="openModalConfirm()" expand="block" class="btn-primary btn-pay-crypto" v-if='(methodPay === "crypto" || methodPay === "swapix") && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("client-space", "client-space.purchase.form.lbl-pay") }} <small>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT <small>({{summary.priceFinalString}})</small></small></div>
                            </div>
                            
                            <div v-if='methodPay === "swapix"'>
                                <div class="btn-primary btn-pay-crypto disabled" v-if='accepts.swapix01 != true || accepts.swapix02 != true || accepts.swapix03 != true || accepts.Conditions != true || accepts.Terms != true'>{{ getMyLanguage("client-space", "client-space.purchase.form.lbl-pay") }} <small>{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}} (PIX)</small></div>
                                <div @click="openModalConfirm()" expand="block" class="btn-primary btn-pay-crypto" v-if='accepts.swapix01 && accepts.swapix02 && accepts.swapix03 && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("client-space", "client-space.purchase.form.lbl-pay") }} <small>{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}} (PIX)</small></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <!-- MODAL CONFIRM -->
            <div class="modal modal-confirm" v-if="modalConfirm">
                <div class="modal-cont">
                    <div class="modal-close" @click="modalConfirm = false"></div>

                    <div class="modal-scroll" ref="contract">
                        <div class="box">
                            <h2 class="txt-center">PRE-SALE AGREEMENT OF THE DNE TOKENS<small>(the "Agreement")</small></h2>
                            
                            <!-- <h3 class="text-center">Between</h3>
                            <p><strong>DNE International Sagl</strong>, (hereinafter the "Company"), a Limited Liability Company (registration number CHE-409.547.100) with registered office in headquartered in Via Cantonale 11, 6900 Lugano -  Switzerland</p>
                            <p class="txt-right">(hereinafter referred as <strong>"Company"</strong>)</p>
                            
                            <p class="and txt-center">and</p>
                            <p><strong>{{$store.state.profile.profile_full_name}}</strong></p>
                            <p class="txt-right">(hereinafter referred as to the <strong>"Buyer"</strong>)</p>
                            <p class="txt-right">(Company and Buyer hereinafter referred together as to the <strong>"Parties"</strong> and each a <strong>"Party"</strong>)</p> -->

                            <h3>Between</h3>
                            <p><strong>DNE International Sagl</strong>, (hereinafter the "Company"), a Limited Liability Company (registration number CHE-409.547.100) with registered office in headquartered in Via Cantonale 11, 6900 Lugano -  Switzerland (hereinafter referred as <strong>"Company"</strong>) and <strong>{{$store.state.profile.profile_full_name}}</strong> (hereinafter referred as to the <strong>"Buyer"</strong>)</p>
                            <p>(Company and Buyer hereinafter referred together as to the <strong>"Parties"</strong> and each a <strong>"Party"</strong>)</p>

                            <h3>Preamble</h3>
                            <p><strong>WHEREAS</strong>, the Company is offering payment tokens (hereinafter called <strong>"DNE Token"</strong>) both for pre-sale and subsequent token sale. The purpose of the DNE Token shall be to act as a mean of payment in the DNE Ecosystem. This <strong>"DNE Pre-Sale"</strong> shall take place between Sep 1st 2023 and October 31st 2023. The <strong>"ICO Token Sale"</strong> shall subsequently occur within the month of November 2023 (the first day of the ICO Token Sale hereinafter referred to as <strong>"ICO Starting Day"</strong>).</p>
                            <p><strong>WHEREAS</strong>, the Buyer with signing this Agreement declares that she/he has read carefully this Agreement before participating to the DNE Pre-sale;</p>
                            <p><strong>NOW THEREFORE</strong>, in consideration of the foregoing, the Parties hereto agree as follows:</p>

                            <h3>Project</h3>
                            <p>The "DNE" project arises in a context in which the "new economy" is increasingly a reality to endure. The emergence of new disruptive business models (rapid evolution and disruption) makes companies and professions obsolete very quickly.</p>
                            <p>The DNE Concept translates into connecting the world, leveraging the transformations caused by the "new economy", through the creation of a Global Ecosystem. The DNE Token will be accessible to everyone and the system enabler.</p>
                            <p>DNE Exchange, DNE Pay and DNE Market are entities of the DNE Ecosystem that integrate, reinforce, consolidate and, through the partnership with the Bank, link the cryptocurrency world to the financial world, putting an end to the separation of both worlds.</p>
                            <p>The company's purpose is to advise, promote and provide services in the field of innovative technologies with a focus on blockchain technologies and tokenomics, as well as the concept of decentralization. The company believes in Crypto technology, therefore decided to develop decentralized technology and started to implement the blockchain technology in a platform to create an users-network with the DNE Token instead of the others FIAT money.</p>
                            <p>The DNE Token is a payment token created on the Stellar Blockchain (Blockchain Stellar).</p>

                            <h3>Purpose of the Company</h3>
                            <p>The DNE Ecosystem aims to create a community of buyers, sellers and partners, at a Global level, creating and implementing a Digital Market solution, where the DNE Token will be the digital asset that drives the ecosystem.</p>

                            <h3>Pre-sale phase’s Conditions</h3>
                            <p>The Buyer expressly acknowledges, represents and warrants that she/he has carefully reviewed the terms of this Agreement and the white paper of DNE Token and fully understands all risks, costs and benefits associated with the purchase of DNE Token in the DNE Pre-Sale.</p>
                            <p>The Buyer purchasing DNE Token shall be aware and accepts that the proceeds collected in the DNE Pre-Sale phase will be used by the Company for financing and developing the DNE Ecosystem. The buyer is also informed and aware that the proceeds from the DNE Pre-sale could be used for financing and implementing the Initial Coin Offering (ICO) procedure, which will raise money for developing the DNE Ecosystem and she/he will receive the DNE Token only at the ICO Starting Day.</p>

                            <h3>Token Purchase and price</h3>
                            <p>The DNE Token is a digital unit. One lot of DNE TOKEN consists of 180 token units, at a price of €0,50 per unit.</p>
                            <p>During the DNE Pre-sale there will be available for sale the total amount of 30,000 Lots of 180 units of DNE Token, priced at €90 per Lot, with a 40% discount, corresponding to €54 per lot, as compensation for keeping lots in stake for 18 months.</p>
                            <p>Only Company’s users can buy DNE Tokens, under the following rights and conditions (i) DNE Pre-sale starts from September 1st 2023, which will last 60 days (sixty days); (ii) the DNE User will have the right to purchase the DNE Token with a 40% discount, with the obligation to keep the respective tokens for a period of 18 months vested, counting from the date of signature of this agreement referred to herein; (iii) the discount cannot be accumulated with others.</p>
                            <p>The minimum purchase order for each DNE User is 1.800 tokens, corresponding to 10 (ten) lots and a cumulative maximum of 250 (two hundred and fifty) lots, corresponding to 45.000 tokens for each purchasing person, or 500 (five hundred) lots for each entity, corresponding to 90.000 tokens.</p>
                            <p>The Buyer hereby declares to purchase {{dataPurchase.quantity}} lot of DNE Token at the price of € 90 per single lot for a total amount which is equal to € {{dataTokenSale.phase.purchase_price}} (the "Purchase Price").</p>
                            <p>The DNE Token purchase shall be facilitated in such a way, that the Purchase Price shall be deposited in and delivered to an Stellar compatible wallet (the "Wallet").</p>
                            <p>The Buyer also agrees to pay the Purchase Price in Euro ("€") to be deposited at Company Wallet address ___________ (the "Wallet") to be managed by the Company, within [Date]. Digital wallets will be made available at the start of the ICO.</p>
                            <p>The Buyer is aware that in order to be eligible to participate in the DNE Pre-sale, she/he must have a stellar blockchain compatible wallet that supports the Stellar token standard in order to receive any DNE Token.</p>
                            
                            <h3>Knowledge required</h3>
                            <p>The Buyer wanting to purchase DNE Token during the DNE Pre-Sale shall ensure that she/he understands and has significant experience of cryptocurrencies, blockchain technologies and services, and that she/he fully understands the risks associated with DNE Token, the DNE Pre-Sale as well as the mechanism related to the use of cryptocurrencies (incl. storage).</p>
                            <p>The Buyer is aware of the fact that she/he may suffer substantial losses with regard to the DNE Token Purchase up to the loss of the full Purchase Price.</p>

                            <h3>Risks</h3>
                            <p>Acquiring DNE Token involves various risks, in particular but not limited to the fact that the Company may not be able to launch its operations and develop its platform due to technical and/or business difficulties or any other difficulties which is not foreseen for the Company at the time of the DNE Pre-sale. Therefore, and prior to acquiring DNE Token, the Buyer should carefully consider the risks, costs, and benefits of acquiring DNE Token within the DNE Pre-Sale, and, if necessary, obtain independent advice in this regard. The Buyer who is not in the position to accept nor to understand the risks associated with the DNE Pre-Sale (incl. the risks related to the non- development of Company network and operations) should not acquire DNE Token, at this stage or later.</p>
                            <p>The Buyer declares and accepts that he/she understands that he/she is the sole responsible for the custody of the DNE Tokens received during a DNE Pre-sale. The DNE Token will be sent on the wallet indicated to the Company by the Buyer. The Buyer will assume all risks associated with the self-custody of the DNE Token.</p>
                            <p>The Company will in no way be responsible for any loss of the DNE Token deposited in the wallets indicated by the Buyer.</p>

                            <h3>Important Disclaimer</h3>
                            <p>By transferring XLM, the native cryptocurrency of the Stellar blockchain on which the DNE Ecosystem is deployed and/or receiving DNE Token in the DNE Pre-Sale, no form of partnership, joint venture or any similar relationship between Buyer and the Company and/or other individuals or entities involved with the deployment of the Company Platform and Project is established/ or created.</p>
                            <p>The Buyer understands and accepts that for the purpose of the development and execution of the business the Company receives the full amount of Euro raised in both the DNE Pre-Sale and the ICO Token Sale (the "ICO Proceeds"). The ICO Proceeds may be used to cover expenses, charges and other costs that may arise by the Company and/or its subcontractors as part of the development and execution of the Company Platform and the DNE Pre-Sale as well as the ICO Token Sale as such. It remains at Company’s sole discretion to decide how to allocate the ICO Proceeds.</p>
                            <p>This Agreement shall not and cannot be considered as an invitation to enter into an investment. It does not constitute or relate in any way nor should it be considered as an offering of securities in any jurisdiction. This Agreement does not include or contain any information or indication that might be considered as a recommendation or that might be used to base any investment decision. DNE Token is a payment token issued by the company and utilized on the DNE Ecosystem and is not intended to be used for any speculative purposes.</p>
                            <p>The Company will be an operative entity managing the DNE Ecosystem and DNE Token is only the payment token of the DNE Ecosystem. Therefore, the company is a financial intermediary according to Swiss Law and therefore subject to the Swiss Anti-Money Laundering Act.</p>
                            <p>Regulatory authorities are carefully scrutinizing businesses and operations associated with cryptocurrencies in the world. In that respect, regulatory measures, investigations or actions may affect the DNE Pre-Sale, Company business and even limit or prevent it from developing its operations in the future. The Buyer undertaking to acquire DNE Token must be aware that the Company business model may change or need to be modified and such modification may be made in the sole discretion of the Company. In such a case, the Buyer undertaking to acquire DNE Token acknowledges and understands that neither DNE Token nor any of its affiliates shall be held liable for any direct or indirect loss or damages caused by such changes.</p>
                            <p>The company will use its best efforts to launch its operations and develop the DNE Ecosystem. The Buyer undertaking to acquire DNE Token acknowledges and understands that the Company does not provide any guarantee that it will manage to establish an operative platform, gain a critical mass of users and that the DNE Token will in the future in fact be able to be used to connect buyers and sellers, ensuring that each receives what was negotiated, in a practical, transparent and secure manner.</p>
                            <p>The Buyer acknowledges and understands therefore that the Company (incl. its affiliates, managers and employees) assumes no liability or responsibility for any loss or damage that would result from or relate to reliance of the capacity to use DNE Token, except in the case of intentional misconduct or gross negligence.</p>

                            <h3>Representation and Warranties</h3>
                            <p>By participating in the DNE Pre-Sale, the Buyer declares in particular, that she/he represents and warrants that she/he:</p>

                            <ul>
                                <li>is acting in her/his own name only, and will be the legal and beneficial owner of the DNE Token as well as the Purchase Price and that all payments by Buyer under this Agreement will be made only in Buyer’s name, from a digital wallet solely owned by the Buyer;</li>
                                <li>is authorized and has full power to purchase DNE Token according to the laws that apply in his jurisdiction of domicile;</li>
                                <li>in case the person is a natural person, is of a sufficient age and capacity under the applicable laws of the jurisdiction in which such Buyer resides;</li>
                                <li>lives in a jurisdiction which allows to the company to sell DNE Token through the DNE Pre-Sale event without requiring any local authorization;</li>
                                <li>is familiar with all related regulations in the specific jurisdiction in which she/he is based and that purchasing cryptographic tokens in that jurisdiction is not prohibited, restricted or subject to additional conditions of any kind;</li>
                                <li>has not acquired the amounts invested directly or indirectly derived from any activities that contravene the laws and regulations of any jurisdiction, including anti-money laundering laws and regulations</li>
                                <li>is not a U.S. citizen, resident or entity (a "U.S. Person") nor is she/he purchasing DNE Token or signing on behalf of a U.S. Person;</li>
                                <li>is not a Chinese resident nor is she/he purchasing DNE Token or signing on behalf of a Chinese resident;</li>
                                <li>is not a South Korean resident nor is she/he purchasing DNE Token or signing on behalf of a South Korean resident;</li>
                                <li>is not acting for the purpose of speculative investment;</li>
                                <li>will not use DNE Token purchased during the DNE Pre-Sale for any illegal activity, including but not limited to money laundering and the financing of terrorism;</li>
                                <li>is solely responsible for determining whether the acquisition of DNE Token is appropriate for her/him;</li>
                                <li>is acquiring DNE Token for future use of the DNE Ecosystem;</li>
                                <li>
                                    agrees and acknowledges that in the case the DNE Token are not construed, interpreted, classified or treated as:
                                    <ul>
                                        <li>any kind of currency;</li>
                                        <li>debentures, stocks or shares issued by any person or entity;</li>
                                        <li>rights, options or derivatives in respect of such debentures, stocks or shares;</li>
                                        <li>rights under a contract for differences or under any other contract the purpose or pretended purpose of which is to secure a profit or avoid a loss;</li>
                                        <li>units in a collective investment scheme;</li>
                                        <li>units in a business trust;</li>
                                        <li>derivatives of units in a business trust; or</li>
                                        <li>any other security, class of securities or form of investment;</li>
                                        <li>understands the risks associated with the DNE Pre-Sale (incl. the risks related to the non-development of DNE Ecosystem and its operations); and has a substantial understanding of the functionality, usage, storage, transmission mechanisms and intricacies associated with cryptographic tokens or coins, and blockchain-based software ledger systems;</li>
                                        <li>understands that the value of DNE Token (if any) over time may experience extreme volatility or depreciate in full;</li>
                                        <li>understands that she/he bears the sole responsibility to determine what tax implications a purchase in the DNE Token may have for her/him and agrees not to hold the company or any other person involved in the proposed sale of the DNE Token liable for any tax liability associated with or arising therefrom.</li>
                                    </ul>
                                </li>
                            </ul>

                            <h3>Limitation of Liability</h3>
                            <p>Neither the Company nor any of its officers, affiliates or agents shall be liable for any indirect, special, incidental, consequential, or exemplary damages of any kind (including, but not limited to, where related to loss of revenue, income or profits, loss of use or data, or damages for business interruption) arising out of or in any way related to the DNE Pre-sale or use of the DNE Tokens or otherwise related to these terms, regardless of the form of action, whether based in contract, tort (including, but not limited to, simple negligence, whether active, passive or imputed), or any other legal or equitable theory (even if the party has been advised of the possibility of such damages and regardless of whether such damages were foreseeable), and (ii) in no event will the aggregate liability of the Company and the Company parties (jointly), whether in contract, warranty, tort (including negligence, whether active, passive or imputed), or other theory, arising out of or relating to these terms or the use of or inability to use the DNE Token, exceed the amount you pay to the company for the DNE Token.</p>
                            <p>The limitations set forth in this Agreement shall not limit or exclude the liability for the gross negligence, fraud or intentional, willful or reckless misconduct of the Company or its representatives, nor the liability for damage from injury to life, body or health by intention or negligence of the Company or its representatives.</p>

                            <h3>Notice: Informal Dispute Resolution</h3>
                            <p>Each Party will notify the other Party in writing of any arbitrable dispute within thirty (30) days of the date it arises, so that the Parties can attempt in good faith to resolve the dispute informally. Notice to the Company shall be sent by e-mail to the email address of the Company at support@dnetoken.com Notice to Buyer shall be by email to the email address of Buyer at {{$store.state.profile.profile_email}} A notice must include (i) name, postal address, email address and telephone number, (ii) a description in reasonable detail of the nature or basis of the dispute, and (iii) the specific relief that the claimant is seeking.</p>

                            <h3>Severability</h3>
                            <p>If any term, clause or provision of this Agreement is held unlawful, void or unenforceable, then that term, clause or provision will be severable from this Agreement and will not affect the validity or enforceability of any remaining part of that term, clause or provision, or any other term, clause or provision of these Terms. In the case of a clause not being valid or in the case that a regulation has not been included therein, this gap shall be replaced by a valid clause that the Parties would have been agreed upon if they would have known the gap.</p>

                            <h3>Applicable Law and Jurisdiction</h3>
                            <p>This Agreement is governed by Swiss law. Any dispute, controversy or claim arising out or in relation to this Agreement, including the validity, invalidity, breach or termination thereof, shall be resolved by the ordinary courts of Lugano.</p>

                            <p>&nbsp;</p>
                            <p>Hereby, accepted and agreed on {{ dateTimeSignature(signature.date || new Date()) }}, by and between:</p>
                            
                            <div class="signatures">
                                <div class="company">
                                    <p>Company</p>

                                    <div class="signature">Dne International Sagl</div>
                                    <p>DNE International Sagl</p>
                                    <p>{{ dateTimeSignature(signature.date || new Date()) }}</p>
                                </div>
                                
                                <div class="buyer">
                                    <p>The Buyer</p>

                                    <div class="signature">{{signature.fullName}}</div>
                                    <p>{{$store.state.profile.profile_full_name}}</p>
                                    <p>{{ dateTimeSignature(signature.date || new Date()) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="confirm-purchase"  v-if="showContent">
                        <!-- <h2 class="title-secondary">{{getMyLanguage("client-space","modal-confirm.title")}}</h2> -->

                        <label class="formName">
                            <span>{{getMyLanguage("compliance","compliance.placeholder.fullName.lbl")}}</span>
                            <input type="text" class="form" :class="{error: errorsForm.fullName == true}" v-model="signature.fullName" v-on:input="signatureName()" @keyup="validateError()" @click="scrollContract()" :placeholder='getMyLanguage("compliance","compliance.placeholder.fullName")'>
                            <small class="error" v-if="errorsForm.fullName == true">{{getMyLanguage("compliance","compliance.error.comparative")}} {{$store.state.profile.profile_full_name}}</small>
                        </label>

                        
                        <div class="form-group-check" v-if='methodPay != "crypto" && methodPay != "swapix"'>
                            <input type="checkbox" id="acceptConfirm" class="form-check" v-model="accepts.Confirm">
                            <label for="acceptConfirm" class="form-check-label"><small>{{getMyLanguage("client-space","modal-confirm.txt01")}} &nbsp;<strong>{{summary.quantityTokenString}}</strong>&nbsp; {{getMyLanguage("client-space","modal-confirm.txt02")}} &nbsp;<strong>{{summary.priceFinalString}}</strong></small></label>
                        </div>
                        
                        <div class="form-group-check" v-if='methodPay === "crypto"'>
                            <input type="checkbox" id="acceptConfirm" class="form-check" v-model="accepts.Confirm">
                            <label for="acceptConfirm" class="form-check-label"><small>{{getMyLanguage("client-space","modal-confirm.txt01")}} &nbsp;<strong>{{summary.quantityTokenString}}</strong>&nbsp; {{getMyLanguage("client-space","modal-confirm.txt02")}} &nbsp;<strong>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} TETHER USDT</strong>&nbsp;({{summary.priceFinalString}})</small></label>
                        </div>
                        
                        <div class="form-group-check" v-if='methodPay === "swapix"'>
                            <input type="checkbox" id="acceptConfirm" class="form-check" v-model="accepts.Confirm">
                            <label for="acceptConfirm" class="form-check-label"><small>{{getMyLanguage("client-space","modal-confirm.txt01")}} &nbsp;<strong>{{summary.quantityTokenString}}</strong>&nbsp; {{getMyLanguage("client-space","modal-confirm.txt02")}} &nbsp;<strong>{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}} (PIX)</strong></small></label>
                        </div>
                        
                        <div class="btn-primary disabled" v-if="!accepts.Confirm || errorsForm.fullName != 'validated'">{{getMyLanguage("client-space","modal-confirm.btn-confirm")}}</div>
                        <div @click="purchase()" class="btn-primary" v-if="accepts.Confirm && errorsForm.fullName == 'validated' && !load">{{getMyLanguage("client-space","modal-confirm.btn-confirm")}}</div>                        
                        <div class="btn-primary load" v-if="load"></div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    
    import Header from '@/components/Header.vue';
    import ClientSpaceResume from '@/components/ClientSpaceResume.vue';
    import Phases from '@/components/Phases.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import {loadStripe} from '@stripe/stripe-js';
    const stripePromise = loadStripe(process.env.VUE_APP_STRIPE_PK);
    let cancelThreeDS = ""
    
    import apiInternational from '@/config/apiInternational.js'

    import moment from "moment";

    import { handleError } from '@/services/errorHandler.js'

    export default {
        components: {
            Header,
            ClientSpaceResume,
            Phases,
        },
        
        beforeMount(){
            this.loginStatus()
        },

        data() {
            return {
                load: false,
                showContent: false,
                modalConfirm: false,
                flagSelected: "en",

                purcaseHide: true,

                phaseID: "",
                urlGetPhase: "",

                dataTokenSale: [],
                dataSummary: {},
                dataPurchase: {},
                disableQuantity: false,

                validateCard: {
                    cardHolder: null,
                    cardNumber: null,
                    expiry: null,
                    cvv: null
                },

                scaLink: "",
                redirectTo: "",

                methodPay: "sepa_swift",
                ticker: '',
                tickerPix: '',
                quoteTimer: 0,
                quoteTimerMinutes: 0,

                errorsForm: {
                    fullName: null
                },

                signature: {
                    fullName: "",
                    date: ""
                },

                accepts: {
                    Conditions: false,
                    Terms: false,
                    Confirm: false
                },

                summary: {},
                blockPurchase: false,
            }
        },

        async mounted(){
            const urlParamsPhase = new URLSearchParams(window.location.search);
            this.phaseID = urlParamsPhase.get('phase-id')

            if ( this.phaseID ){
                this.urlGetPhase = `/api/v2/ico/token_sale/purchase/${this.phaseID}`
            } else {
                this.urlGetPhase = "/api/v2/ico/token_sale/purchase/current/"
            }
            
            await apiInternational.get(this.urlGetPhase)
             .then(response => {
                this.dataTokenSale = response.data

                setTimeout(() => {
                    this.showContent = true
                    this.showContent = true
                    this.load = false
                }, 500);

                setTimeout(() => {
                    this.showWallets = true
                    this.showContent = true
                    this.load = false

                    this.ticker = this.dataTokenSale.payment_types.filter(paymentTypes => paymentTypes.paymentTypeCode === "crypto")[0].ticker
                    this.tickerPix = this.dataTokenSale.payment_types.filter(paymentTypes => paymentTypes.paymentTypeCode === "swapix")[0].ticker
                }, 1000);
             })
            .catch(error => {
                setTimeout(() => {
                    console.log(this.$store.state.profile.account_type)
                    
                    handleError(error, this.flagSelected);
                }, 3000);
            })
    
            
            this.dataPurchase.phaseId = this.dataTokenSale.phase.phase_id
            this.dataPurchase.quantity = this.dataTokenSale.quota.min_amount
            this.dataPurchase.account_id = this.$store.state.profile.account_id
            this.dataPurchase.paymentTypeId = "732e682f-a99f-4914-b2b4-c56351b12ca6"

            this.dataPurchase.cardHolder = ""
            this.dataPurchase.cardNumber = ""
            this.dataPurchase.expiry = ""
            this.dataPurchase.cvv = ""
            
            setTimeout(() => {
                this.showContent = true
            }, 1000);

            setTimeout(() => {
                this.dataPurchase.account_id = this.$store.state.profile.account_id
                this.showContent = true
            }, 3000);

            setTimeout(() => {
                this.dataPurchase.account_id = this.$store.state.profile.account_id
                this.showContent = true
            }, 5000);
            
            setTimeout(() => {
                this.dataPurchase.account_id = this.$store.state.profile.account_id
                this.showContent = true
            }, 10000);


            // const urlParams = new URLSearchParams(window.location.search);
            // const phasePurchaseIdParams = urlParams.get('phasePurchaseId');
            // const quantityParams = urlParams.get('quantity');

            // if ( urlParams != '' ){
            //     this.dataPurchase.phasePurchaseId = phasePurchaseIdParams
            //     this.dataPurchase.quantity = quantityParams
            //     this.disableQuantity = true
            // }
            
            this.upSummary()
            

            setTimeout(() => {
                $('.products-list .form-group-check:nth-of-type(1) label').trigger('click');
            }, 100);

            this.quoteTimer = 130
            this.countDownQuote();
        },

        methods: {
            loginStatus(){
                if ( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null){
                    window.location.href = '/'
                }
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.client-space.purchase");
                document.head.querySelector('meta[name=description]').content = ''
            },
            
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            changeCurrent(){
                setTimeout(() => {
                    this.showWallets = true
                    this.showContent = true
                    this.load = false

                    this.ticker = this.dataTokenSale.payment_types.filter(paymentTypes => paymentTypes.paymentTypeCode === "crypto")[0].ticker
                    this.tickerPix = this.dataTokenSale.payment_types.filter(paymentTypes => paymentTypes.paymentTypeCode === "swapix")[0].ticker
                }, 1000);
            },

            upSummary(){
                let price = this.dataTokenSale.phase.product_price
                let quantityTokens = this.dataTokenSale.phase.product_quantity
                let bonus = this.dataTokenSale.phase.phase_bonus
                let discount = this.dataTokenSale.phase.phase_discount
                let min = this.dataTokenSale.quota.min_amount
                let max = this.dataTokenSale.quota.available

                $('.btnQtd.btn-less').removeClass('disable')
                $('.btnQtd.btn-more').removeClass('disable')

                if ( this.dataPurchase.quantity <= min ){
                    this.dataPurchase.quantity = min
                    this.dataPurchase.quantity = min

                    $('.btnQtd.btn-less').addClass('disable')
                    $('.quantityBuy .tooltip-less').fadeToggle();
                }
                
                if ( this.dataPurchase.quantity >= max){
                    this.dataPurchase.quantity = max
                    this.dataPurchase.quantity = max

                    $('.btnQtd.btn-more').addClass('disable')
                    $('.quantityBuy .tooltip-more').fadeToggle();
                }
                
                setTimeout(() => {
                    $('.quantityBuy .tooltip').hide()
                }, 2000);


                this.summary.originalPrice = price * this.dataPurchase.quantity
                this.summary.quantityToken = quantityTokens * this.dataPurchase.quantity
                this.summary.priceFinal = (price * this.dataPurchase.quantity)-((price * this.dataPurchase.quantity)*(discount / 100))
                this.summary.totalToken = (this.dataPurchase.quantity * quantityTokens) * (1 + (bonus / 100));
                this.summary.priceFinalCrypto = (this.summary.priceFinal * this.ticker)
              
                this.quantityPurchase()
            },


            quantityLess(){
                this.interval = setInterval(() => {
                    setTimeout(() => {
                            this.upSummary(this.dataPurchase.quantity -= 1)
                    }, 200);
                }, 150)
            },

            quantityMore(){
                this.interval = setInterval(() => {
                    setTimeout(() => {
                            this.upSummary(this.dataPurchase.quantity += 1)
                    }, 200);
                }, 150)
            },

            quantityStop(){
                clearInterval(this.interval)
            },

            purchase(){
                this.load = true
                const purchase = this.dataPurchase;

                apiInternational.post("/api/v1/ico/token_sale/purchase/", purchase)
                .then(response => {
                    if ( response.data.success ){
                        window.location.href = '/' + response.data.returnUrl

                    } else if ( !response.data.success && response.data.status === "requires_action" && response.data.nextAction.type === "use_stripe_sdk" ){
                        
                        stripePromise.then(responseStripe => {
                            responseStripe.confirmCardPayment(response.data.nextAction.clientSecret)
                            .then(result => {
                                if ( result.error ){
                                    cancelThreeDS = result.error.type
                                    console.log(cancelThreeDS)
                                    window.location.href = `/${this.flagSelected}/client-space/purchase-error?error=${result.error.type}`
                                    
                                } else if ( result.paymentIntent.status === "succeeded" ){
                                    window.location.href = `/${response.data.returnUrl}`
                                }
                            })
                        })
                        .catch(error => {
                            window.location.href = `/${this.flagSelected}/client-space/purchase-error?error=${error}`
                        })
                    } else {
                        window.location.href = `/${this.flagSelected}/client-space/purchase-error?error=error_3ds`
                    }
                })
                .catch(error => {
                    window.location.href = `/${this.flagSelected}/client-space/purchase-error?error=${error.response.data[0]}`
                })
            },

            checkPurchaseCreditCard(){
                window.location.href = `/${this.redirectTo}`
            },

            quantityPurchase() {
                let qtdTokens = this.summary.quantityToken
                let totalTokens = this.summary.totalToken
                let priceOriginal = this.summary.originalPrice
                let priceFinal = this.summary.priceFinal
                let priceFinalCrypto = this.summary.priceFinalCrypto
                
                qtdTokens = qtdTokens.toLocaleString("pt-PT")
                totalTokens = totalTokens.toLocaleString("pt-PT")
                priceOriginal = priceOriginal.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})
                priceFinal = priceFinal.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})
                priceFinalCrypto = priceFinalCrypto.toLocaleString("pt-PT")
                
                this.summary.quantityTokenString = qtdTokens
                this.summary.totalTokenString = totalTokens
                this.summary.originalPriceString = priceOriginal
                this.summary.priceFinalString = priceFinal
                this.summary.priceFinalCryptoString = priceFinalCrypto
            },

            countDownQuote() {                
                if(this.quoteTimer > 0) {
                    setTimeout(() => {
                        this.quoteTimer -= 1

                        if ( this.quoteTimer >= 60 ){
                            this.quoteTimerMinutes = this.quoteTimer / 60
                            this.quoteTimerMinutes = String(this.quoteTimerMinutes).split(".")[0]
                        }

                        this.countDownQuote()
                    }, 1000)
                } else{
                    this.changeCurrent()
                    this.dataTokenSale.payment_types[2].ticker = this.ticker
                    this.upSummary()
                    this.quoteTimer = 130
                    this.countDownQuote()
                }
            },

            openModalConfirm(){
                if ( this.methodPay === "credit_card" ){
                    if ( this.dataPurchase.cardHolder.length < 5 || this.dataPurchase.cardHolder == null ){ this.validateCard.cardHolder = false } else { this.validateCard.cardHolder = true }
                    if ( this.dataPurchase.cardNumber.length < 16 || this.dataPurchase.cardNumber == null ){ this.validateCard.cardNumber = false } else { this.validateCard.cardNumber = true }
                    if ( this.dataPurchase.expiry.length < 2 || this.dataPurchase.expiry == null ){ this.validateCard.expiry = false } else { this.validateCard.expiry = true }
                    if ( this.dataPurchase.cvv.length < 3 || this.dataPurchase.cvv == null ){ this.validateCard.cvv = false } else { this.validateCard.cvv = true }

                    if ( this.validateCard.cardHolder == true && this.validateCard.cardNumber == true && this.validateCard.expiry == true && this.validateCard.cvv == true ){
                        this.validateCard .cardHolder = true
                        this.modalConfirm = true
                    }
                } else {
                    this.modalConfirm = true
                    // this.errorsPost.dataPurchase = false
                }
            },

            dateTimeSignature(value) {
                return moment(value).format('ll');
            },

            scrollContract() {
                const contractDiv = this.$refs.contract;
                contractDiv.scrollTop = contractDiv.scrollHeight;
            },

            signatureName(){
                const nameSignature = this.signature.fullName;
                const nameSignatureLowercase = nameSignature.toLowerCase();
                const words = nameSignatureLowercase.split(' ');
                const wordsUppercase = words.map(words => words.charAt(0).toUpperCase() + words.slice(1));
                const nameSignatureFinished = wordsUppercase.join(' ');
                this.signature.fullName = nameSignatureFinished;
            },

            validateError(){
                if ( this.signature.fullName.toLowerCase() == this.$store.state.profile.profile_full_name.toLowerCase() ){
                    this.errorsForm.fullName = "validated"
                } else {
                    this.errorsForm.fullName = true
                }

                if ( this.signature.fullAddress != "" ){
                    this.errorsForm.fullAddress = false
                }
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
        
        created () {
            document.title = this.getMyLanguage("seo","title.client-space.purchase");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-clientSpace.scss" lang="scss" scoped />
<style src="./custom-clientSpacePurchase.scss" lang="scss" scoped />

<style scoped>
    .alert-lost-token{animation: lostToken .4s alternate;}

    @keyframes lostToken{
        0% {top: 20px;}
        100% {top: 0;}
    }
</style>